import { match as armorPiece } from "../../../src/params/armorPiece.ts";
import { match as color } from "../../../src/params/color.ts";
import { match as emoji } from "../../../src/params/emoji.ts";
import { match as itemId } from "../../../src/params/itemId.ts";
import { match as player } from "../../../src/params/player.ts";
import { match as potion } from "../../../src/params/potion.ts";
import { match as potionColor } from "../../../src/params/potionColor.ts";
import { match as profile } from "../../../src/params/profile.ts";
import { match as username } from "../../../src/params/username.ts";
import { match as uuid } from "../../../src/params/uuid.ts";

export const matchers = { armorPiece, color, emoji, itemId, player, potion, potionColor, profile, username, uuid };